import { getRange } from "./range";

const getWert = (anteil, kaufpreis, eigene_mittel, jahreseinkommen) => {

    const hypothekarZinssatz = 1;//0.01;
    const finanzierung = anteil * kaufpreis
    const hypoZins = 0.05;
    const unterhaltZins = 0.01;
    const mietanteilZins = 0.035;
    const hypothekarZins = (eigene_mittel >= finanzierung) ? 0 : (finanzierung - eigene_mittel) * hypoZins / 12;
    const unterhaltskosten = kaufpreis * unterhaltZins / 12;
    const mietanteilanleger = (kaufpreis - finanzierung) * mietanteilZins / 12;
    const monatlicheKalkulatorischeBelastung = hypothekarZins + unterhaltskosten + mietanteilanleger;

    const mietEigentum = (finanzierung - eigene_mittel) * hypothekarZinssatz/12/100 + mietanteilanleger
    const monatlicheKostenMietEigentum = eigene_mittel > finanzierung ? mietanteilanleger : mietEigentum;

    const werte = {
        finanzierungProzenz: anteil * 100,
        finanzierung: finanzierung,
        hypothekarZins: hypothekarZins,
        unterhaltskosten: unterhaltskosten,
        mietanteilanleger: mietanteilanleger,
        monatlicheKalkulatorischeBelastung: monatlicheKalkulatorischeBelastung,
        ausreichendEigenmittel: !(eigene_mittel < finanzierung * 0.2),
        gegebeneTragbarkeit: monatlicheKalkulatorischeBelastung * 12 < 0.33 * jahreseinkommen,
        monatlicheKostenMietEigentum: monatlicheKostenMietEigentum
    }
    return werte;
}


const warn = (msg) => {
    //console.log(msg)
}


function check(wertTabelle, eigene_mittel, untereLimite, kaufpreis, chosenFinanzierung) {
    const checks = {
        "Deine Tragbarkeit": checkTragbarkeit(wertTabelle),
        "Deine Eigenmittel": checkEigenmittel(eigene_mittel, untereLimite, kaufpreis, chosenFinanzierung),
        "Deine Finanzierung": checkFinanzierung(eigene_mittel, kaufpreis, wertTabelle),
    }
    return checks;
}

const getWertetabelle = (
    kaufpreis,
    eigene_mittel,
    jahreseinkommen,
) => {

    const untereLimite = 0.32;
    const obereLimite = 0.50;

    const range = getRange((untereLimite + 0.01) * 100, (obereLimite - 0.01) * 100);
    const wertTabelle = range.map(r => getWert(r, kaufpreis, eigene_mittel, jahreseinkommen));

    const filteredWertetabelle = wertTabelle.filter(entry => {
        return entry.ausreichendEigenmittel && entry.gegebeneTragbarkeit;
    })

    const chosenFinanzierung = filteredWertetabelle.length > 0 && filteredWertetabelle.sort((a, b) => {
        return a.finanzierung - b.finanzierung;
    }).reverse()[0];

    const checks = check(wertTabelle, eigene_mittel, untereLimite, kaufpreis, chosenFinanzierung);
    // if (Object.values(checks).length > 0) {
    //     Object.entries(checks).forEach(([key, value]) => {
    //         console.log(key, value)
    //     })
    // }

    // console.log(wertTabelle)

    // console.log(chosenFinanzierung, checks)

    return [chosenFinanzierung, checks];
}

function checkFinanzierung(eigene_mittel, kaufpreis, wertTabelle) {
    if (eigene_mittel / kaufpreis > 0.49) {
        const text = "Anteil darf maximal 49% des Kaufpreises betragen.";
        warn(text);
        return [text, true];
    }
    // else if (wertTabelle[0]) {
    //     const text = "Die Finanzierung muss mindestens 32% des Kaufpreises betragen.";
    //     warn(text);
    //     return [text, false];
    // } 
    else {
        const text = "Der Finanzierungsanteil ist möglich.";
        warn(text);
        return [text, true];
    }
}

function checkEigenmittel(eigene_mittel, untereLimite, kaufpreis, finanzierung) {
    const limit = (untereLimite + 0.01) * kaufpreis;
    if (eigene_mittel < (limit * 0.2)) {
        const text = "Zu wenig Eigenmittel vorhanden.";
        warn(text);
        return [text, false];
    } else if (finanzierung.hypothekarZins === 0) {
        const text = "Der Anteil kann zu 100% eigenfinanziert werden.";
        warn(text);
        return [text, true];
    } else {
        const text = "Ausreichend Eigenmittel vorhanden.";
        warn(text);
        return [text, true];
    }
}

function checkTragbarkeit(wertTabelle) {
    if (!wertTabelle[0].gegebeneTragbarkeit) {
        const text = "Die Tragbarkeit ist nicht erfüllt.";
        warn(text);
        return [text, false];
    } else {
        const text = "Die Tragbarkeit ist gegeben.";
        warn(text);
        return [text, true];
    }
}

// 49% ->

export function getFinanzierung(
    kaufpreis,
    eigene_mittel,
    jahreseinkommen,
) {
    return getWertetabelle(kaufpreis, eigene_mittel, jahreseinkommen);
}