import React from "react";
import imgVideoPlaceholder from "../../images/platzhalter-video.svg";
import videoErklaervideo from "../../videos/Getyourhouse_Erklaervideo_220608.mp4";


export default function Intro({

}) {
    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto px-4 py-20 sm:px-6 lg:py-20 lg:px-8">
                <div className="mx-auto lg:w-1/2">
                    <h2 className="text-3xl font-bold text-theme_blue text-center">So funktioniert's</h2>
                    <p className="max-w-md text-lg mt-8 text-center sm:text-xl md:max-w-3xl">
                        Mit getyourhouse erfüllst du dir den Traum vom Eigenheim. Die Lösung ist dabei ein Kauf in zwei Schritten. Hier erfährst du wie eine Kaufabwicklung mit getyourhouse funktioniert.                    </p>
                </div>
                <div className="mx-auto mt-12 lg:w-3/4">
                    <video width="100%" height="450" controls poster={imgVideoPlaceholder}>
                        <source
                            src={videoErklaervideo}
                            type="video/mp4"
                        />
                    </video>
                </div>
            </div>
        </div>
    )
}