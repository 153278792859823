import logo from './logo.svg';
import './App.css';
import Header from './components/top/Header';
import Hero from './components/hero/Hero';
import Footer from './components/bottom/Footer';
import FooterMeta from './components/bottom/FooterMeta';
import LogoList from './components/elements/LogoList';
import Advantages from './components/elements/Advantages';
import Intro from './components/elements/Intro';

function App() {
  return (
    <div className="App h-screen w-screen">
      <Header />
      <Hero />
      <div className='relative bg-white z-20'>
        <Intro />
        <Advantages />
        <LogoList />
        <Footer />
        <FooterMeta />
      </div>
    </div>
  );
}

export default App;
