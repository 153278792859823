import React from "react";
import imgLogo20min from "../../images/logo_20min.svg";
import imgLogoHandelszeitung from "../../images/logo_handelszeitung.svg";
import imgLogoTagesanzeiger from "../../images/logo_tagesanzeiger.svg";
import imgBgMedien from "../../images/backgroundimage-medien.jpg";
import ImgLink from "./ImgLink";


export default function LogoList({

}) {
    return (
      <div className="relative">
        <div className="-z-1 w-full absolute inset-y-0 right-0 h-full bg-gradient-to-r from-theme_green to-theme_red">
            <img
                className="absolute inset-0 w-full h-full object-cover"
                src={imgBgMedien}
                alt="getyourhouse Hero Background"
            />
        </div>
        <div className="max-w-7xl relative z-10 mx-auto px-4 py-20 sm:px-6 lg:py-20 lg:px-8">
          <h2 className="text-3xl font-bold text-white text-center">Bekannt aus den Medien</h2>
          <div className="flow-root mt-8 lg:mt-8">
            <div className="mt-3 flex space-x-4 justify-between">
              <div className="bg-white px-14 py-8 rounded-lg border border-theme_graylight flex items-center transition-shadow hover:shadow-xl">
                <ImgLink
                      href="#"
                      src={imgLogoHandelszeitung}
                />
              </div>
              <div className="bg-white px-14 py-8 rounded-lg border border-theme_graylight flex items-center transition-shadow hover:shadow-xl">
              <ImgLink
                      href="#"
                      src={imgLogoTagesanzeiger}
                />
              </div>
              <div className="bg-white px-14 py-8 rounded-lg border border-theme_graylight flex items-center transition-shadow hover:shadow-xl">
              <ImgLink
                      href="#"
                      src={imgLogo20min}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}