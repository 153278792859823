import React from "react";
import NumberFormat from 'react-number-format';
import { Controller } from "react-hook-form";
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';


export default function NumberInput({
    id,
    label,
    placeholder = "0.00",
    autoFocus = false,
    value,
    onChange = () => { },
    step = 10000,
    min,
    max,
    control
}) {
    return (
        <div key={id}>
            <label htmlFor="price" className="relative block text-md text-xl text-theme_blue font-bold">
                {label}
                <div className="absolute inset-y-0 right-0 pr-[11px] flex items-center pointer-events-none">
                    <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">

                <Controller
                    control={control}
                    name={id}
                    defaultValue={value}
                    render={({ field: { name, value, ref, onChange } }) => (
                        <>
                            <div className='relative'>
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 sm:text-sm md:text-lg">CHF</span>
                                </div>
                                <NumberFormat
                                    // format="#### #### #### ####"
                                    thousandSeparator={'\''}
                                    decimalScale={2}
                                    name={name}
                                    value={value}
                                    ref={ref}
                                    onValueChange={({ value }) => {
                                        onChange(parseInt(value))
                                    }}
                                    className="focus:ring-theme_blue focus:border-theme_blue block w-full pl-12 text-right sm:text-sm md:text-lg text-theme_blue border-gray-300 rounded-md"
                                />
                            </div>
                            <input
                                type="range"
                                value={value}
                                className="w-full h-2 mt-4 mb-6 bg-gray-200 rounded-lg appearance-none cursor-pointer range-lg dark:bg-gray-700"
                                min={min}
                                max={max}
                                onChange={onChange}
                                step={step}
                            />

                        </>
                    )}
                />
            </div>
        </div>
    )
}