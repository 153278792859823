import React from "react";
import Img from "./Img";
import Link from "./Link";

import logo from "../../images/logo_getyourhouse.svg";
import logoNegative from "../../images/logo_getyourhouse_negative.svg";



export default function Logo({
    className,
    imageClassName,
    isNegative = false
}) {

    const logoPath = (!isNegative) ? logo : logoNegative;

    return (
        <Link
            href={"/"}
            title="getyourhouse"
            className={className}
        >
            <Img
                className={imageClassName}
                src={logoPath}
            />

        </Link>
    )
}