import React from "react";
import imgVorteilWohneigentum from "../../images/vorteile-getyourhouse-kinderwagen.svg";
import imgVorteilSparpotential from "../../images/vorteile-getyourhouse-sparschwein.svg";
import imgVorteilDigitalisierung from "../../images/vorteile-getyourhouse-tablet.svg";
import imgVorteilVersicherung from "../../images/vorteile-getyourhouse-versicherung.svg";

export default function Advantages({

}) {
    return (
      <div className="advantages bg-zinc-200">
        <div className="max-w-7xl mx-auto px-4 py-20 sm:px-6 lg:py-20 lg:px-8">
          <h2 className="text-3xl font-bold text-theme_blue text-center">getyourhouse bringt dir viele Vorteile:</h2>
          <div className="flow-root mt-8">
            <div className="mt-4 md:grid md:grid-cols-2 lg:grid-cols-4 md:gap-8">
              <div className="mb-6 md:mb-0">
                  <div className="advantageWrapper bg-white p-8 rounded-lg border border-theme_graylight transition-shadow hover:shadow-xl flex items-center mb-8 justify-center">
                    <img
                          className="w-auto h-40 transition-transform"
                          src={imgVorteilWohneigentum}
                          alt="getyourhouse Vorteil Wohneigentum"
                      />
                  </div>
                  <p className="font-bold">Du kannst früher Wohneigentum erwerben.</p>
              </div>
              <div className="mb-6 md:mb-0">
                  <div className="advantageWrapper bg-white p-8 rounded-lg border border-theme_graylight transition-shadow hover:shadow-xl flex items-center mb-8 justify-center">
                    <img
                          className="w-auto h-40 transition-transform"
                          src={imgVorteilSparpotential}
                          alt="getyourhouse Vorteil Sparpotenzial"
                      />
                  </div>
                  <p className="font-bold">Du wohnst günstiger als in Miete – mit einem Sparpotenzial von mehreren Tausend Franken im Jahr.</p>
              </div>
              <div className="mb-6 md:mb-0">
                  <div className="advantageWrapper bg-white p-8 rounded-lg border border-theme_graylight transition-shadow hover:shadow-xl flex items-center mb-8 justify-center">
                    <img
                          className="w-auto h-40 transition-transform"
                          src={imgVorteilDigitalisierung}
                          alt="getyourhouse Vorteil Digitalisierung"
                      />
                  </div>
                  <p className="font-bold">Die Finanzierung mit getyourhouse wird einfach digital abgewickelt.</p>
              </div>
              <div className="mb-6 md:mb-0">
                  <div className="advantageWrapper bg-white p-8 rounded-lg border border-theme_graylight transition-shadow hover:shadow-xl flex items-center mb-8 justify-center">
                    <img
                          className="w-auto h-40 transition-transform"
                          src={imgVorteilVersicherung}
                          alt="getyourhouse Vorteil vertrauenswürdige Partner"
                      />
                  </div>
                  <p className="font-bold">Du kannst dich auf starke, vertrauenswürdige Partner wie CS oder Helvetia verlassen.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}