import React from "react";
import Img from "./Img";
import Link from "./Link";


export default function ImgLink({
    href,
    src,
    alt,
    title,
    imgClassName,
    className
}) {
    return (
        <Link
            href={href}
            title={title}
            className={className}
        >
            <Img
                src={src}
                alt={alt}
                title={title}
                className={imgClassName}
            />
        </Link>
    )
}