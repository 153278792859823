import React from "react"

export default function Link({
    href,
    title,
    className = "text-base text-white hover:text-black",
    children
}) {
    return (
        <a href={href} className={className} title={title}>
            {children}
        </a>
    )
}