import { ArrowLeftIcon, ArrowRightIcon, CurrencyDollarIcon, EmojiHappyIcon, EmojiSadIcon, GlobeIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';
import React, { useCallback, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { getFinanzierung } from '../../helpers/calculate';
import { getFormattedNumber } from '../../helpers/format';
import { useForm, Controller } from "react-hook-form";
import NumberInput from './NumerInput';

export default function Rechner({



}) {

    const defaultValues = {
        kaufpreis: 1000000,
        eigene_mittel: 240000,
        jahreseinkommen: 140000,

    };

    const form = useForm(
        { defaultValues }
    );




    const kaufpreis = form.watch("kaufpreis");
    const eigene_mittel = form.watch("eigene_mittel");
    const jahreseinkommen = form.watch("jahreseinkommen");


    const [calcResult, setCalcResult] = useState(getFinanzierung(kaufpreis, eigene_mittel, jahreseinkommen));

    useEffect(() => {
        // TODO: possibility to debounce
        const res = getFinanzierung(kaufpreis, eigene_mittel, jahreseinkommen);
        setCalcResult(res);
    }, [kaufpreis, eigene_mittel, jahreseinkommen]);

    const [{
        ausreichendEigenmittel,
        finanzierung,
        finanzierungProzenz,
        gegebeneTragbarkeit,
        hypothekarZins,
        mietanteilanleger,
        monatlicheKalkulatorischeBelastung,
        unterhaltskosten,
        monatlicheKostenMietEigentum
    }, checks] = calcResult;

    const errors = Object.entries(checks).filter(([k, [txt, ok]]) => !ok)

    const notPossible = errors.length > 0
    const notifications = Object.entries(errors).map(([index, [k, [txt, ok]]]) => {
        return `${txt}`; // ${k}:
    });

    const results = {
        possible: {
            name: 'Finanzierung möglich!',
            buttonText: 'Direkt Antrag stellen',
            icon: EmojiHappyIcon,
            description: (
                <ul>
                    <li>Monatliche Kosten: CHF {getFormattedNumber(Math.round(monatlicheKostenMietEigentum, 2))}</li>
                    <li>Dein Miteigentum: {finanzierungProzenz}%</li>
                </ul>
            ),
            colorStyle: "text-theme_green"
        },
        notPossible: {
            name: 'Finanzierung nicht möglich!',
            buttonText: 'Direkt Antrag stellen',
            icon: EmojiSadIcon,
            description: (
                <ul>
                    {notifications.map((text, index) => (
                        <li key={`notification-${index}`}>{text}</li>
                    ))}
                </ul>
            ),
            colorStyle: "text-theme_red"
        }
    }

    const result = (!notPossible) ? results.possible : results.notPossible

    return (
        <div
            className="mx-auto"
        >
            <NumberInput
                id="kaufpreis"
                label="Kaufpreis der Immobilie"
                placeholder={1000000}
                step={10000}
                value={kaufpreis}
                // min={600000}
                min={0}
                max={2500000}
                control={form.control}
            />

            <NumberInput
                id="eigene_mittel"
                label="Eigene Mittel"
                placeholder={240000}
                step={5000}
                value={eigene_mittel}
                // min={90000}
                min={0}
                max={1490000}
                control={form.control}
            />


            <NumberInput
                id="jahreseinkommen"
                label="Jahreseinkommen (Brutto)"
                placeholder={140000}
                step={5000}
                value={jahreseinkommen}
                // min={60000}
                min={0}
                max={600000}
                control={form.control}
            />



            <div
                className='mx-auto'
            >
                <div className="flex flex-col">
                    <div key={``} className="flex items-center flex-col border-0">
                        <div key={result.name} className={`py-2 text-center ${result.colorStyle}`}>
                            <div className='flex items-center justify-center gap-4'>
                                <result.icon className="h-20 w-20 flex-grow-0" aria-hidden="true" />
                                <div className='text-left flex flex-col flex-1'>
                                    <span className="block mt-4 text-xl font-medium">{result.name}</span>
                                    <div className="flex mb-4 text-2xl font-medium">
                                        <div className="text-base opacity-80">
                                            {result.description}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!notPossible &&
                            <a href="https://plattform.getyourhouse.ch"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="w-full flex items-center justify-center px-8 py-3 border border-transparent font-medium rounded-md text-white bg-theme_green hover:bg-theme_blue md:py-4 text-2xl md:px-10">
                                <ArrowRightIcon
                                    className='h-6 w-6 mr-2 stroke-2'
                                />
                                {result.buttonText}
                            </a>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}