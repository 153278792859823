import React from "react";


export default function Img({
    className = "w-full h-auto",
    src,
    alt,
    title
}) {
    return (
        <img
            className={className}
            src={src}
            alt={alt}
            title={title}
        />
    )
}