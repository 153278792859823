import React from 'react'
import Link from '../elements/Link'
import Logo from '../elements/Logo'

export default function Footer() {
    return (
        <footer className="bg-theme_blue" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className="grid grid-cols-1 gap-8 xl:col-span-4">
                    <div className="md:grid md:grid-cols-4 md:gap-8">
                        <div>
                            <Logo 
                                isNegative={true}
                                imageClassName="w-48 h-auto"
                            />
                        </div>
                        <div className="mt-6 md:mt-0">
                            <h3 className="text-lg font-bold text-white">getyourhouse AG</h3>
                            <p className="text-base text-white">Am Mattenhof 2a<br></br>
                            CH-6010 Kriens</p>
                            <p className="text-base text-white">
                                <Link
                                    href= "tel:+41 41 000 00 00"
                                    title= "Telefon getyourhouse AG"
                                >
                                    +41 41 000 00 00<br></br>
                                </Link>
                                <Link
                                    href= "mailto:info@getyourhouse.ch"
                                    title= "E-Mail getyourhouse"
                                >
                                    info@getyourhouse
                                </Link>
                            </p>
                        </div>
                        <div className="mt-6 md:mt-0">
                            <ul role="list" className="mb-6">
                                <li>
                                    <a href="#" className="text-base text-white hover:text-black">Immobilie kaufen </a>
                                </li>

                                <li>
                                    <a href="#" className="text-base text-white hover:text-black">Immobilie verkaufen </a>
                                </li>

                                <li>
                                    <a href="#" className="text-base text-white hover:text-black">Partner werden </a>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-6 md:mt-0">
                            <ul role="list">
                                <li>
                                    <a href="#" className="text-base text-white hover:text-black">Über uns </a>
                                </li>

                                <li>
                                    <a href="#" className="text-base text-white hover:text-black">Partner werden </a>
                                </li>

                                <li>
                                    <a href="#" className="text-base text-white hover:text-black">So funktioniert's </a>
                                </li>
                                <li>
                                    <a href="#" className="text-base text-white hover:text-black">FAQ </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}