import React from "react";
import bgHero from "../../images/getyourhouse-bg3.jpg";
import Rechner from "../rechner/Rechner";

export default function Hero({

}) {
    return (
        <div
            className=""
        >
            <main className="relative">
                <div className="">
                    <img
                        className="fixed -z-1 inset-0 w-screen h-screen object-cover"
                        src={bgHero}
                        alt="getyourhouse Hero Background"
                    />
                </div>
                <div className="mx-auto relative z-10 max-w-7xl w-full pt-16 pb-20 text-center lg:py-20 lg:text-left">
                    <div className="px-4 lg:w-min">
                        <h1 className="text-xl font-bold text-theme_blue md:text-4xl md:leading-11 mb-8 whitespace-nowrap">
                            Du hast eine Traumimmobilie –<br></br>
                            wir die Finanzierungslösung.
                        </h1>
                        <div className="bg-white rounded-lg p-8">
                            <Rechner />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}